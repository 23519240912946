import { Typography } from "@mui/material";
import StepComponent from "../components/step.component";
const pattern = "^[a-zA-Z0-9_.,@/:#=\\[\\]\\s-]*$";

export default {
  title: "Device name",
  description:
    "Give this FlexiObox a recognizable name where you can search for in the Fleet",
  helperText: {
    header: (
      <Typography variant="h6">
        {" "}
        FlexiObox device name.
      </Typography>
    ),
  },
  component: StepComponent,
  schema: {
    required: ["name"],
    properties: {
      name: {
        $id: "basic_name",
        type: "string",
        pattern,
        title: "FlexiObox device name",
        description:
          "Give this FlexiObox a recognizable name where you can search for in the Fleet",
      }
    },
  },
  uiSchema: {
    name: {},
  },
  transformErrors: (errors) => {
    return errors.map((error) => {
      if (error.name === "pattern" && error.property === ".name") {
        error.message =
          "Please enter only letters, numbers, spaces, and the following special characters: _.,@/:#=[]-";
      }
      return error;
    });
  },
};
