export default {
  "SigenStor EC 3.0 SP": { maxACDCPower: 3000, maxMPPTPower: 6000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 3000 },
  "SigenStor EC 3.6 SP": { maxACDCPower: 3600, maxMPPTPower: 7360, maxBatteryChargePower: 7360, maxBatteryDischargePower: 3600 },
  "SigenStor EC 4.0 SP": { maxACDCPower: 4000, maxMPPTPower: 8000, maxBatteryChargePower: 8000, maxBatteryDischargePower: 4000 },
  "SigenStor EC 4.6 SP": { maxACDCPower: 4600, maxMPPTPower: 9200, maxBatteryChargePower: 9200, maxBatteryDischargePower: 4600 },
  "SigenStor EC 5.0 SP": { maxACDCPower: 5000, maxMPPTPower: 10000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 5000 },
  "SigenStor EC 6.0 SP": { maxACDCPower: 6000, maxMPPTPower: 12000, maxBatteryChargePower: 12000, maxBatteryDischargePower: 6000 },
  "SigenStor EC 5.0 TP": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 8000, maxBatteryDischargePower: 5000 },
  "SigenStor EC 6.0 TP": { maxACDCPower: 6000, maxMPPTPower: 9600, maxBatteryChargePower: 9600, maxBatteryDischargePower: 6000 },
  "SigenStor EC 8.0 TP": { maxACDCPower: 8000, maxMPPTPower: 12800, maxBatteryChargePower: 12800, maxBatteryDischargePower: 8000 },
  "SigenStor EC 10.0 TP": { maxACDCPower: 10000, maxMPPTPower: 16000, maxBatteryChargePower: 16000, maxBatteryDischargePower: 10000 },
  "SigenStor EC 12.0 TP": { maxACDCPower: 12000, maxMPPTPower: 19200, maxBatteryChargePower: 19200, maxBatteryDischargePower: 12000 },
  "SigenStor EC 15.0 TP": { maxACDCPower: 15000, maxMPPTPower: 24000, maxBatteryChargePower: 24000, maxBatteryDischargePower: 15000 },
  "SigenStor EC 17.0 TP": { maxACDCPower: 17000, maxMPPTPower: 27200, maxBatteryChargePower: 27200, maxBatteryDischargePower: 17000 },
  "SigenStor EC 20.0 TP": { maxACDCPower: 20000, maxMPPTPower: 32000, maxBatteryChargePower: 32000, maxBatteryDischargePower: 20000 },
  "SigenStor EC 25.0 TP": { maxACDCPower: 25000, maxMPPTPower: 40000, maxBatteryChargePower: 40000, maxBatteryDischargePower: 25000 },
  "SigenStor Hybrid 3.0 SP": { maxACDCPower: 3000, maxMPPTPower: 6000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 3000 },
  "SigenStor Hybrid 3.6 SP": { maxACDCPower: 3680, maxMPPTPower: 7360, maxBatteryChargePower: 7360, maxBatteryDischargePower: 3680 },
  "SigenStor Hybrid 4.0 SP": { maxACDCPower: 4000, maxMPPTPower: 8000, maxBatteryChargePower: 8000, maxBatteryDischargePower: 4000 },
  "SigenStor Hybrid 4.6 SP": { maxACDCPower: 4600, maxMPPTPower: 9200, maxBatteryChargePower: 9200, maxBatteryDischargePower: 4600 },
  "SigenStor Hybrid 5.0 SP": { maxACDCPower: 5000, maxMPPTPower: 10000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 5000 },
  "SigenStor Hybrid 6.0 SP": { maxACDCPower: 6000, maxMPPTPower: 12000, maxBatteryChargePower: 12000, maxBatteryDischargePower: 6000 },
  "SigenStor Hybrid 8.0 SP": { maxACDCPower: 8000, maxMPPTPower: 16000, maxBatteryChargePower: 16000, maxBatteryDischargePower: 8000 },
  "SigenStor Hybrid 10.0 SP": { maxACDCPower: 10000, maxMPPTPower: 20000, maxBatteryChargePower: 20000, maxBatteryDischargePower: 10000 },
  "SigenStor Hybrid 12.0 SP": { maxACDCPower: 12000, maxMPPTPower: 24000, maxBatteryChargePower: 24000, maxBatteryDischargePower: 12000 },
  "SigenStor Hybrid 5.0 TP": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 8000, maxBatteryDischargePower: 5000 },
  "SigenStor Hybrid 6.0 TP": { maxACDCPower: 6000, maxMPPTPower: 9600, maxBatteryChargePower: 9600, maxBatteryDischargePower: 6000 },
  "SigenStor Hybrid 8.0 TP": { maxACDCPower: 8000, maxMPPTPower: 12800, maxBatteryChargePower: 12800, maxBatteryDischargePower: 8000 },
  "SigenStor Hybrid 10.0 TP": { maxACDCPower: 10000, maxMPPTPower: 16000, maxBatteryChargePower: 16000, maxBatteryDischargePower: 10000 },
  "SigenStor Hybrid 12.0 TP": { maxACDCPower: 12000, maxMPPTPower: 19200, maxBatteryChargePower: 19200, maxBatteryDischargePower: 12000 },
  "SigenStor Hybrid 15.0 TP": { maxACDCPower: 15000, maxMPPTPower: 24000, maxBatteryChargePower: 24000, maxBatteryDischargePower: 15000 },
  "SigenStor Hybrid 17.0 TP": { maxACDCPower: 17000, maxMPPTPower: 27200, maxBatteryChargePower: 27200, maxBatteryDischargePower: 17000 },
  "SigenStor Hybrid 20.0 TP": { maxACDCPower: 20000, maxMPPTPower: 32000, maxBatteryChargePower: 32000, maxBatteryDischargePower: 20000 },
  "SigenStor Hybrid 25.0 TP": { maxACDCPower: 25000, maxMPPTPower: 40000, maxBatteryChargePower: 40000, maxBatteryDischargePower: 25000 }
};