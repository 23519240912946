export default {
  "INTEG M MHS-3K-30": { maxACDCPower:3000, maxMPPTPower:4800, maxBatteryChargePower:3000, maxBatteryDischargePower:3000},
  "INTEG M MHS-3.6K-30": { maxACDCPower:3600, maxMPPTPower:5760, maxBatteryChargePower:3600, maxBatteryDischargePower:3600},
  "INTEG M MHS-4.2K-30": { maxACDCPower:4200, maxMPPTPower:6720, maxBatteryChargePower:4200, maxBatteryDischargePower:4200},
  "INTEG M MHS-5K-30": { maxACDCPower:5000, maxMPPTPower:8000, maxBatteryChargePower:5000, maxBatteryDischargePower:5000},
  "INTEG M MHS-6K-30": { maxACDCPower:6000, maxMPPTPower:9600, maxBatteryChargePower:6000, maxBatteryDischargePower:6000},
  "INTEG M MHS-8K-30": { maxACDCPower:8000, maxMPPTPower:12800, maxBatteryChargePower:8000, maxBatteryDischargePower:8000},
  "INTEG M MHT-4K-25": { maxACDCPower:4000, maxMPPTPower:6000, maxBatteryChargePower:4000, maxBatteryDischargePower:4000},
  "INTEG M MHT-5K-25": { maxACDCPower:5000, maxMPPTPower:7500, maxBatteryChargePower:5000, maxBatteryDischargePower:5000},
  "INTEG M MHT-6K-25": { maxACDCPower:6000, maxMPPTPower:9000, maxBatteryChargePower:6000, maxBatteryDischargePower:6000},
  "INTEG M MHT-8K-25": { maxACDCPower:8000, maxMPPTPower:12000, maxBatteryChargePower:8000, maxBatteryDischargePower:8000},
  "INTEG M MHT-10K-25": { maxACDCPower:10000, maxMPPTPower:15000, maxBatteryChargePower:10000, maxBatteryDischargePower:10000},
  "INTEG M MHT-10K-40": { maxACDCPower:10000, maxMPPTPower:15000, maxBatteryChargePower:10000, maxBatteryDischargePower:10000},
  "INTEG M MHT-12K-25": { maxACDCPower:12000, maxMPPTPower:18000, maxBatteryChargePower:12000, maxBatteryDischargePower:12000},
  "INTEG M MHT-12K-40": { maxACDCPower:12000, maxMPPTPower:18000, maxBatteryChargePower:12000, maxBatteryDischargePower:12000},
  "INTEG M MHT-15K-40": { maxACDCPower:15000, maxMPPTPower:22500, maxBatteryChargePower:15000, maxBatteryDischargePower:15000},
  "INTEG M MHT-20K-40": { maxACDCPower:20000, maxMPPTPower:30000, maxBatteryChargePower:20000, maxBatteryDischargePower:20000},
  "INTEG M MHT-25K-100": { maxACDCPower:25000, maxMPPTPower:37500, maxBatteryChargePower:25000, maxBatteryDischargePower:25000},
  "INTEG M MHT-30K-100": { maxACDCPower:30000, maxMPPTPower:45000, maxBatteryChargePower:30000, maxBatteryDischargePower:30000},
  "INTEG M MHT-36K-100": { maxACDCPower:36000, maxMPPTPower:54000, maxBatteryChargePower:36000, maxBatteryDischargePower:36000},
  "INTEG M MHT-40K-100": { maxACDCPower:40000, maxMPPTPower:60000, maxBatteryChargePower:40000, maxBatteryDischargePower:40000},
  "INTEG M MHT-50K-100": { maxACDCPower:50000, maxMPPTPower:75000, maxBatteryChargePower:50000, maxBatteryDischargePower:50000},
}