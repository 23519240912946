export default {
	"X1-Hybrid-3.0 G3": { maxACDCPower: 3000, maxMPPTPower: 4500, maxBatteryChargePower: 4500, maxBatteryDischargePower: 3000 },
	"X1-Hybrid-3.7 G3": { maxACDCPower: 3680, maxMPPTPower: 5500, maxBatteryChargePower: 5500, maxBatteryDischargePower: 3680 }, 
	"X1-Hybrid-4.6 G3": { maxACDCPower: 4600, maxMPPTPower: 6900, maxBatteryChargePower: 6900, maxBatteryDischargePower: 4600 },
	"X1-Hybrid-5.0 G3": { maxACDCPower: 4999, maxMPPTPower: 7500, maxBatteryChargePower: 7500, maxBatteryDischargePower: 4999 }, 
	"X1-Hybrid-3.0 G4": { maxACDCPower: 3000, maxMPPTPower: 4500, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"X1-Hybrid-3.7 G4": { maxACDCPower: 3680, maxMPPTPower: 5500, maxBatteryChargePower: 3680, maxBatteryDischargePower: 3680 },
	"X1-Hybrid-5.0 G4": { maxACDCPower: 5000, maxMPPTPower: 7500, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"X1-Hybrid-6.0 G4": { maxACDCPower: 6000, maxMPPTPower: 9000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"X1-Hybrid-7.5 G4": { maxACDCPower: 7500, maxMPPTPower: 10000, maxBatteryChargePower: 7500, maxBatteryDischargePower: 7500 },
	"X1-FIT-3.7 G3": { maxACDCPower: 3680, maxMPPTPower: 0, maxBatteryChargePower: 3600, maxBatteryDischargePower: 3600 },
	"X1-FIT-4.6 G3": { maxACDCPower: 4600, maxMPPTPower: 0, maxBatteryChargePower: 4600, maxBatteryDischargePower: 4600 },
	"X1-FIT-5.0 G3": { maxACDCPower: 4999, maxMPPTPower: 0, maxBatteryChargePower: 4999, maxBatteryDischargePower: 4999 },
	"X1-FIT-3.7 G4": { maxACDCPower: 3680, maxMPPTPower: 0, maxBatteryChargePower: 3600, maxBatteryDischargePower: 3600 },
	"X1-FIT-4.6 G4": { maxACDCPower: 4600, maxMPPTPower: 0, maxBatteryChargePower: 4600, maxBatteryDischargePower: 4600 },
	"X1-FIT-5.0 G4": { maxACDCPower: 5000, maxMPPTPower: 0, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"X1-FIT-6.0 G4": { maxACDCPower: 6000, maxMPPTPower: 0, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"X1-FIT-7.5 G4": { maxACDCPower: 7500, maxMPPTPower: 0, maxBatteryChargePower: 7500, maxBatteryDischargePower: 7500 },
	"X3-Hybrid-5.0 G3": { maxACDCPower: 5000, maxMPPTPower: 7500, maxBatteryChargePower: 7500, maxBatteryDischargePower: 5000 },
	"X3-Hybrid-6.0 G3": { maxACDCPower: 6000, maxMPPTPower: 9000, maxBatteryChargePower: 9000, maxBatteryDischargePower: 6000 },
	"X3-Hybrid-8.0 G3": { maxACDCPower: 8000, maxMPPTPower: 12000, maxBatteryChargePower: 12000, maxBatteryDischargePower: 8000 },
	"X3-Hybrid-10.0 G3": { maxACDCPower: 10000, maxMPPTPower: 15000, maxBatteryChargePower: 15000, maxBatteryDischargePower: 10000 },
	"X3-Hybrid-5.0 G4": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"X3-Hybrid-6.0 G4": { maxACDCPower: 6000, maxMPPTPower: 10000, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"X3-Hybrid-8.0 G4": { maxACDCPower: 8000, maxMPPTPower: 12000, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
	"X3-Hybrid-10.0 G4": { maxACDCPower: 10000, maxMPPTPower: 15000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"X3-Hybrid-12.0 G4": { maxACDCPower: 12000, maxMPPTPower: 18000, maxBatteryChargePower: 12000, maxBatteryDischargePower: 12000 },
	"X3-Hybrid-15.0 G4": { maxACDCPower: 15000, maxMPPTPower: 18000, maxBatteryChargePower: 15000, maxBatteryDischargePower: 15000 },
	"X3-FIT-8.0 G3": { maxACDCPower: 8000, maxMPPTPower: 0, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
	"X3-FIT-10.0 G3": { maxACDCPower: 10000, maxMPPTPower: 0, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"X3-FIT-6.0 G4": { maxACDCPower: 6000, maxMPPTPower: 0, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"X3-FIT-8.0 G4": { maxACDCPower: 8000, maxMPPTPower: 0, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
	"X3-FIT-10.0 G4": { maxACDCPower: 10000, maxMPPTPower: 0, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"X3-FIT-15.0 G4": { maxACDCPower: 15000, maxMPPTPower: 0, maxBatteryChargePower: 15000, maxBatteryDischargePower: 15000 },
	"X3-ULT-15K": { maxACDCPower: 15000, maxMPPTPower: 30000, maxBatteryChargePower: 30000, maxBatteryDischargePower: 15000 },
	"X3-ULT-19.9K": { maxACDCPower: 19999, maxMPPTPower: 40000, maxBatteryChargePower: 40000, maxBatteryDischargePower: 19900 },
	"X3-ULT-20K": { maxACDCPower: 20000, maxMPPTPower: 40000, maxBatteryChargePower: 40000, maxBatteryDischargePower: 20000 },
	"X3-ULT-25K": { maxACDCPower: 25000, maxMPPTPower: 50000, maxBatteryChargePower: 48000, maxBatteryDischargePower: 25000 },
	"X3-ULT-30K": { maxACDCPower: 30000, maxMPPTPower: 60000, maxBatteryChargePower: 48000, maxBatteryDischargePower: 30000 },
};