export default {
	"RAI-3K-48ES-5G": { maxACDCPower: 3000, maxMPPTPower: 0, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"RHI-3K-48ES-5G": { maxACDCPower: 3000, maxMPPTPower: 7000, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"RHI-3.6K-48ES-5G": { maxACDCPower: 3600, maxMPPTPower: 7000, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"RHI-4.6K-48ES-5G": { maxACDCPower: 4600, maxMPPTPower: 8000, maxBatteryChargePower: 4600, maxBatteryDischargePower: 5000 },
	"RHI-5K-48ES-5G": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RHI-6K-48ES-5G": { maxACDCPower: 6000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RHI-3P5K-HVES-5G": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"RHI-3P6K-HVES-5G": { maxACDCPower: 6000, maxMPPTPower: 9600, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"RHI-3P8K-HVES-5G": { maxACDCPower: 8000, maxMPPTPower: 12800, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
	"RHI-3P10K-HVES-5G": { maxACDCPower: 10000, maxMPPTPower: 16000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"S5-EH1P3K-L": { maxACDCPower: 3000, maxMPPTPower: 4800, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"S5-EH1P3.6K-L": { maxACDCPower: 3600, maxMPPTPower: 5700, maxBatteryChargePower: 3000, maxBatteryDischargePower: 3000 },
	"S5-EH1P4.6K-L": { maxACDCPower: 4600, maxMPPTPower: 8000, maxBatteryChargePower: 4600, maxBatteryDischargePower: 5000 },
	"S5-EH1P5K-L": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"S5-EH1P6K-L": { maxACDCPower: 6000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"S6-EH3P5K": { maxACDCPower: 5000, maxMPPTPower: 8000, maxBatteryChargePower: 5000, maxBatteryDischargePower: 5000 },
	"S6-EH3P6K": { maxACDCPower: 6000, maxMPPTPower: 9600, maxBatteryChargePower: 6000, maxBatteryDischargePower: 6000 },
	"S6-EH3P8K": { maxACDCPower: 8000, maxMPPTPower: 12800, maxBatteryChargePower: 8000, maxBatteryDischargePower: 8000 },
	"S6-EH3P10K": { maxACDCPower: 10000, maxMPPTPower: 16000, maxBatteryChargePower: 10000, maxBatteryDischargePower: 10000 },
	"S6-EH3P12K": { maxACDCPower: 12000, maxMPPTPower: 19200, maxBatteryChargePower: 12000, maxBatteryDischargePower: 12000 },
	"S6-EH3P15K": { maxACDCPower: 15000, maxMPPTPower: 24000, maxBatteryChargePower: 15000, maxBatteryDischargePower: 15000 },
	"S6-EH3P20K": { maxACDCPower: 20000, maxMPPTPower: 32000, maxBatteryChargePower: 20000, maxBatteryDischargePower: 20000 },
	"S6-EH3P30K": { maxACDCPower: 30000, maxMPPTPower: 60000, maxBatteryChargePower: 66000, maxBatteryDischargePower: 66000 },
	"S6-EH3P40K": { maxACDCPower: 40000, maxMPPTPower: 80000, maxBatteryChargePower: 70000, maxBatteryDischargePower: 70000 },
	"S6-EH3P50K": { maxACDCPower: 50000, maxMPPTPower: 96000, maxBatteryChargePower: 70000, maxBatteryDischargePower: 70000 },
};