import INTRO from "./intro.step";
import BASIC from "./basic.step";
import EMAIL from "./email.step";
import ADDRESS from "./address.step";
import CONVERTER from "./converter.step";
import BATTERY from "./battery.step";
import PV from "./pv-panels.step";
import ENERGY_TARIFF from "./energy-tariff.step";
import METER from "./meter.step";
import PHASES from "./phases.step";
import SUMMARY from "./summary.step";

export const stepsMap = {
  INTRO,
  BASIC,
  EMAIL,
  ADDRESS,
  CONVERTER,
  BATTERY,
  PV,
  ENERGY_TARIFF,
  METER,
  PHASES,
  SUMMARY,
};

export const steps = Object.entries(stepsMap).map(([id, step]) => ({
  id,
  ...step,
}));

export const STEP_IDS = Object.fromEntries(
  Object.entries(stepsMap).map(([id, step]) => [id, id])
);
