import { Box, Tooltip } from "@mui/material";
import useMediaQuery from "@lifepowr/components/src/utils/use-media-query";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { isEmpty, startCase } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

export const COMMON_KEY = "common";
export const FILTER_DELIMITER = "__";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, mediaQuery }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 5,
  marginBottom: mediaQuery.isSmall || mediaQuery.isMedium ? 0 : 10,
  "&:last-child": {
    marginBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)((props) => ({
  overflow: "hidden",
  borderRadius: 5,
  minHeight:
    props.mediaQuery.isSmall || props.mediaQuery.isMedium ? "32px" : undefined,
  "& > .MuiAccordionSummary-content": {
    margin: 0,
  },
  "&:hover:not(.Mui-expanded)": {
    backgroundColor: "#e8e4e0",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 5,
  paddingTop: 0,
}));

export const FilterSection = ({
  sectionId,
  title,
  content,
  totalSelected,
  selectedFilterTab,
  setSelectedFilterTab,
}) => {
  const mediaQuery = useMediaQuery();
  return (
    <Accordion
      expanded={selectedFilterTab === sectionId}
      mediaQuery={mediaQuery}
    >
      <AccordionSummary
        mediaQuery={mediaQuery}
        onClick={() =>
          selectedFilterTab === sectionId
            ? setSelectedFilterTab("")
            : setSelectedFilterTab(sectionId)
        }
      >
        <Typography
          variant={
            mediaQuery.isSmall || mediaQuery.isMedium ? "subtitle1" : "h6"
          }
          fontWeight="600"
        >
          {title} <TotalSelected totalSelected={totalSelected} />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

const TotalSelected = ({ totalSelected }) =>
  totalSelected ? (
    <span style={{ fontSize: 16, color: "#c4c9cd" }}>({totalSelected})</span>
  ) : (
    ""
  );

export const FiltersMainContainer = ({ children }) => {
  const mediaQuery = useMediaQuery();
  const styles =
    mediaQuery.isSmall || mediaQuery.isMedium
      ? {
          display: "flex",
          flexDirection: "column",
          gap: 5,
          padding: "0 5px",
        }
      : {
          height: "100%",
          overflow: "hidden",
          overflowY: "auto",
          marginLeft: 10,
        };
  return <Box style={styles}>{children}</Box>;
};

const FilterCount = ({ count }) => (
  <span
    style={{
      fontSize: 10,
      borderRadius: 5,
      background: "#c4c9cd",
      padding: "2px 4px",
    }}
  >
    {count}
  </span>
);

// <FilterCount count={count} />
const FilterCheckLabel = ({ title, count, checked }) => (
  <Box fontWeight={checked ? "bold" : "normal"}>
    {startCase(title)}
  </Box>
);

const FilterCheckboxControl = ({
  checked,
  onChange,
  disabled,
  title,
  count,
}) => {
  return (
    <Tooltip title={disabled ? "You can only select 12 filters." : ""}>
      <FormControlLabel
        style={{ marginBottom: 0 }}
        control={
          <Checkbox
            disabled={disabled}
            style={{ padding: 4 }}
            checked={checked}
            onChange={(ev) => onChange(ev.target.checked)}
            size="small"
          />
        }
        label={
          <FilterCheckLabel checked={checked} title={title} count={count} />
        }
      />
    </Tooltip>
  );
};

const consistentMappingNames = {
  "safe": "unknown",
  "dumbBattery": "self-consumption"
};

const FilterGroup = ({
  name,
  values,
  isChecked,
  onChange,
  totalSelected,
  checkBoxListDisabled,
}) => {
  const list = Object.entries(values).map(([value, count]) => ({
    key: `${name}${FILTER_DELIMITER}${value}`,
    count,
    value,
  }));
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="600">
        {startCase(name)} {totalSelected}
      </Typography>
      <Box paddingLeft="5px">
        <FormGroup>
          {list.map(({ key, count, value }) => (
            <FilterCheckboxControl
              checked={isChecked(key)}
              onChange={(changedValue) => onChange(key, changedValue)}
              key={key}
              title={consistentMappingNames[value] || value}
              count={count}
              disabled={!isChecked(key) && checkBoxListDisabled}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export const FiltersContainer = ({
  filterValues,
  isChecked,
  onChange,
  totalSelected,
  checkBoxListDisabled,
  hiddenGroups = [],
}) => {
  const groups = Object.entries(filterValues || {})
    // We are only interested in filterValues of type object as they contain nested items to act as checkboxes
    .filter((v) => typeof v[1] === "object")
    .filter(([groupName, _]) => !hiddenGroups.includes(groupName))
    .map(([groupName, values]) => ({ groupName, values }));
  return (
    <Box padding="10px" paddingTop="0px" display="grid" gap="10px">
      {isEmpty(groups) ? (
        <Box>No filters available!</Box>
      ) : (
        groups.map(({ groupName, values }, index) => (
          // Group level
          <FilterGroup
            key={index}
            name={groupName}
            values={values}
            isChecked={isChecked}
            onChange={onChange}
            totalSelected={totalSelected}
            checkBoxListDisabled={checkBoxListDisabled}
          />
        ))
      )}
    </Box>
  );
};
