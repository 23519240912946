import { useState, useEffect } from 'react';
import { isEqual, get } from 'lodash';
import useSocket from "@lifepowr/components/src/components/socket/useSocket";
import { API } from 'Amplify';

function getReportedSettings(thing) {
    return thing?.shadow?.name?.settings?.reported;
}

function getReportedConfiguration(thing) {
    return { ...thing.shadow.reported.configuration, address: get(thing, 'shadow.reported.configuration.addressObj') ?? get(thing, 'shadow.reported.configuration.address')};
}

function getDesiredSettings(thing) {
    return thing?.shadow?.name?.settings?.desired;
}

function getDesiredConfiguration(thing) {
    return {
        ...thing?.shadow?.desired?.configuration,
        address: thing?.shadow?.desired?.configuration?.addressObj ?? thing?.shadow?.desired?.configuration?.address
    };
}

function isPrimitive(value) {
    return typeof value !== 'object' && value !== undefined;
}

function calculateDiffObject(desired, reported) {
    if (!desired || !reported) return { root: true };

    return Object.keys(desired).reduce((acc, key) => {
        const desiredValue = desired[key];
        const reportedValue = reported[key];

        // Skip if values are equal
        if (isEqual(desiredValue, reportedValue)) return acc;

        // Handle primitive values
        if (isPrimitive(desiredValue)) {
            acc[key] = true;
        }

        // Handle nested objects recursively
        if (typeof desiredValue === 'object') {
            acc[key] = calculateDiffObject(desiredValue, reportedValue);
        }

        // Handle arrays recursively
        if (Array.isArray(desiredValue)) {
            acc[key] = desiredValue.map((_, index) => calculateDiffObject(desiredValue[index], reportedValue[index]))
        }

        return acc;
    }, {});
}

/**
 * Hook to manage device state including reported and desired settings/configuration
 * @param {Object} thing - The device thing object
 * @param {Boolean} connected - The device connectivity status
 * @returns {Object} Device state and diff information
 */
export function useDeviceState(thing, connected) {
    const { publish = () => undefined } = useSocket();

    const [schemas, setSchemas] = useState(null);
    const [reportedSettings, setReportedSettings] = useState(null);
    const [reportedConfiguration, setReportedConfiguration] = useState(null);
    const [desiredSettings, setDesiredSettings] = useState(null);
    const [desiredConfiguration, setDesiredConfiguration] = useState(null);
    const [settings, setSettings] = useState(null);
    const [configuration, setConfiguration] = useState(undefined);
    const [settingsDiff, setSettingsDiff] = useState({});
    const [configurationDiff, setConfigurationDiff] = useState({});

    const fetchSchemas = async () => {
        try {
            // Perp
            const prefix = `fleet/devices/${thing.thingName}`;
            const schemaConfig = { task: "schemas", args: { type: "settings" } };

            // Get schemas
            const results = connected ?
                await publish(`${prefix}/state/interact`, schemaConfig, true) :
                await API.get("IO_API", `/devices/${thing.thingName}/schema`);

            // Set schemas
            setSchemas(results);
        } catch (e) {
            console.error('error fetching schemas', e);
        }
    };

    // Update states when thing changes
    useEffect(() => {
        const _reportedSettings = getReportedSettings(thing);
        const _reportedConfiguration = getReportedConfiguration(thing);
        const _desiredSettings = getDesiredSettings(thing);
        const _desiredConfiguration = getDesiredConfiguration(thing);

        setReportedSettings(_reportedSettings);
        setReportedConfiguration(_reportedConfiguration);
        setDesiredSettings(_desiredSettings);
        setDesiredConfiguration(_desiredConfiguration);
        setSettings(connected ? _reportedSettings : _desiredSettings);
        setConfiguration(connected ? _reportedConfiguration : _desiredConfiguration);
    }, [thing, connected]);

    // Calculate diffs when settings/configuration change
    useEffect(() => {
        const settingsDiffObj = calculateDiffObject(desiredSettings, reportedSettings);
        const configDiffObj = calculateDiffObject(desiredConfiguration, reportedConfiguration);
        setSettingsDiff(settingsDiffObj);
        setConfigurationDiff(configDiffObj);
    }, [reportedSettings, desiredSettings, reportedConfiguration, desiredConfiguration]);

    useEffect(() => {
        fetchSchemas();
    }, [connected, publish]);

    return {
        connected,
        schemas,
        settings,
        configuration,
        diffObj: { settings: settingsDiff, configuration: configurationDiff }
    };
} 