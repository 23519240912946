import { get, isEmpty, merge, orderBy, reduce } from 'lodash';
import Converters from './converters';
import { nestedOmit } from '../../../../utils';

function generateBatteryBrandModelNames(batteryConfig) {
    const batteryTitle = batteryConfig.title
    const [brand, ...rest] = batteryTitle.split(' ')
    const model = rest.join(' ')
    return { brand, model }
}
/**
 * Generates a key/value map where key is either the `batteryType.default` or the `title` of the schema definition
 * @param {*} schemas 
 * @returns {object}
 */
function generateBatterySchemaMap(schemas) {
    return reduce(schemas.definitions.Converter.properties.battery.anyOf, (acc, { $ref }) => {
        const [__, batteryKey] = $ref.split('#/definitions/')
        const batteryConfig = schemas.definitions[batteryKey]
        const batteryReference = get(batteryConfig, 'properties.batteryType.default') || batteryConfig.title
        acc[batteryReference] = batteryConfig
        return acc
    }, {})
}

/**
 * @param {string} settingsBatteryType value of `batteryType` saved in the settings
 */
export function generateBatteryDataFromBatteryType(_settingsBatteryType, schemas) {
    let settingsBatteryType = _settingsBatteryType
    if (settingsBatteryType === "None") {
        // Start with just a prefill to avoid a crash on the form
        settingsBatteryType = "GoodWeLXU"
    }
    const originalBatterySchemaMap = generateBatterySchemaMap(schemas)
    const batteryConfig = originalBatterySchemaMap[settingsBatteryType]
    if (!settingsBatteryType || !batteryConfig) return { brand: '', model: '' }
    return generateBatteryBrandModelNames(batteryConfig)
}

export function generateBatterySchema(settings, schemas) {
    const battery = {
        type: 'object',
        title: 'Battery Brand',
        oneOf: []
    };

    const selectedBattery = settings?.converters[0].battery?.batteryType
    const { brand: selectedBrand, model: selectedModel } = generateBatteryDataFromBatteryType(selectedBattery, schemas)

    const originalBatterySchema = reduce(schemas.definitions.Converter.properties.battery.anyOf, (acc, { $ref }) => {
        const [__, batteryKey] = $ref.split('#/definitions/')
        const batteryConfig = schemas.definitions[batteryKey]
        const { brand, model } = generateBatteryBrandModelNames(batteryConfig)
        if (['NoBattery', 'UnsupportedBattery', 'LifepowrBattery'].includes(batteryKey)) return acc
        acc[brand] = acc[brand] ? orderBy([...acc[brand], model]) : [model]
        return acc
    }, {})

    orderBy(Object.entries(originalBatterySchema), ([brand]) => brand).forEach(([brand, models]) => {
        let firstOne = false;
        let firstBrand = false;
        const item = {
            type: 'object',
            title: brand,
            properties: {
                brand: {
                    type: 'string',
                    default: brand,
                    const: brand,
                },
                model: {
                    type: 'string',
                    enum: models
                }
            }
        };

        models.forEach((model) => {
            if (brand === selectedBrand) {
                firstBrand = true;
                if (model === selectedModel) {
                    firstOne = true;
                }
            }
            if (!item.properties.model.default) item.properties.model.default = model;
        });

        if (firstOne || firstBrand) {
            battery.oneOf.unshift(item);
        } else {
            battery.oneOf.push(item);
        }
    });

    const batteryDetailsSchema = getBatteryDefinitionFromBrandModel(schemas, { brand: selectedBrand, model: selectedModel })
    return { batteryBrandModelSchema: battery, batteryDetailsSchema };
}

export function getBatteryDefinitionFromBrandModel(schema, { brand, model } = {}) {
    if (!brand || !model) return null
    const title = `${brand} ${model}`
    const batteryDefinition = Object.entries(schema.definitions).find(([batteryKey, value]) => value.title?.includes(title))
    return batteryDefinition
}

export function getBatteryDefaultDetails(batteryDefinition) {
    const [__, { properties }] = batteryDefinition
    const battery = Object.entries(properties).reduce((acc, [key, value]) => {
        acc[key] = value.default
        return acc
    }, {})
    return isEmpty(battery) ? null : battery
}

/**
 * Generate battery settings from a selected brand/model
 */
export function getBatteryDetailsByBrandModel(schema, batteryBrandModel) {
    const batteryDefinition = getBatteryDefinitionFromBrandModel(schema, batteryBrandModel)
    if (!batteryDefinition) return null
    return getBatteryDefaultDetails(batteryDefinition)
}

export function getConverterDetailsByBrandModel({ brand: brandName, model: modelName }) {
    const models = Converters[brandName] || {}
    const model = models[modelName] || {}
    return model
}

export function generateConverterSchema(configuration) {
    const converter = {
        $id: "converterSchema",
        type: 'object',
        title: 'Converter Brand',
        oneOf: [
            {
                type: 'object',
                title: 'No Inverter',
                properties: {
                    brand: {
                        type: 'string',
                        default: 'DefaultInverter',
                        const: 'DefaultInverter',
                    },
                    model: {
                        type: 'string',
                        enum: ['Custom'],
                    },
                },
            },
        ]
    };
    const { converter: selectedConverter = '' } = configuration;
    const [selectedBrand, ...rest] = selectedConverter.split(' ');
    const selectedModel = rest.join(' ');

    Object.entries(Converters).forEach(([brand, models]) => {
        let firstOne = false;
        let firstBrand = false;
        const conv = {
            type: 'object',
            title: brand,
            properties: {
                brand: {
                    type: 'string',
                    default: brand,
                    const: brand,
                },
                model: {
                    type: 'string',
                    enum: []
                }
            }
        };
        const adjModels = [['Custom', {}], ...Object.entries(models)];

        adjModels.forEach(([model, obj]) => {
            conv.properties.model.enum.push(model);
            if (brand === selectedBrand) {
                firstBrand = true;
                if (model === selectedModel) {
                    firstOne = true;
                }
            }
            if (!conv.properties.model.default) conv.properties.model.default = model;
            // if(!conv.properties.model.const) conv.properties.model.const = model;
        });



        if (firstOne || firstBrand) {
            converter.oneOf.unshift(conv);
        } else {
            converter.oneOf.push(conv);
        }
    });

    return converter;
}


export function updateConvertersData(newFormConfig, formData) {
    const brand = get(newFormConfig, 'configuration.converter.brand')
    const model = get(newFormConfig, 'configuration.converter.model')
    const brandExisting = get(formData, 'configuration.converter.brand')
    const modelExisting = get(formData, 'configuration.converter.model')
    const existingConverter = get(formData, 'settings.converters[0]')
    const representation = get(Converters, `${brand}.${model}`)
    const converterDataHasChanged = brand !== brandExisting || model !== modelExisting

    if (converterDataHasChanged) {
        return merge(newFormConfig, {
            settings: {
                converters: [representation || existingConverter],
            },
        });
    }

    return newFormConfig
}

export function generateConverterData(configuration) {
    let { converter = 'DefaultInverter' } = configuration;
    if (converter.includes('undefined')) converter = 'DefaultInverter';
    const [brand, ...rest] = converter.split(' ');
    let model = rest.join(' ');
    const { [brand]: brandObj } = Converters;
    if (brandObj && !(model in brandObj) && model !== 'Custom') model = Object.keys(brandObj)[0];
    return { brand, model }
}

export function generateNewFormConfig(configuration = {}, settings = {}) {
    const {
        address,
        reference,
    } = configuration;
    const { brand, model } = generateConverterData(configuration)
    return {
        settings: nestedOmit(settings || {}),
        configuration: {
            address,
            converter: {
                brand,
                model
            },
            reference
        },
    }

}

export const isInvertorHybrid = ({ brand, model } = {}) => {
    const converterBrand = Converters[brand] || {};
    const invertor = converterBrand[model];
    return invertor?.maxMPPTPower > 0;
};
