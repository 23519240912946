import Button from "@mui/material/Button";
import Link from "@mui/material/Button";

const { getQuery } = require("../utils/url");

import InsaverLogo from './insaver/insaver_logo.jsx';
import SunergyLogo from './sunergy/sunergy_logo.jsx';
import RensolLogo from './rensol/rensol_logo.jsx';
import EnergreenLogo from './energreen/energreen_logo.jsx';

const clients =  {
  'smarthomecontrol.be': {
    title: 'Insaver Smart Home Control',
    idp: 'Insaver',
    loginClass: 'loginFormHidden',
    footer: <>Insaver - <Link target="_blank" href="https://insaver.be/helpdesk-shc/">Helpdesk</Link></>,
    logo: InsaverLogo,
    ean: false,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    signOut: 'https://my.smarthomecontrol.be/user/logout',
  },
  'sunergy.nl': {
    title: 'Sunergy',
    favicon: "sunergy/favicon.ico",
    appleTouchIcon: "sunergy/apple-touch-icon.png",
    favicon32: "sunergy/favicon-32x32.png",
    favicon16: "sunergy/favicon-16x16.png",
    idp: 'Sunergy',
    loginClass: 'loginForm',
    footer: <>Sunergy - <Link target="_blank" href="https://sunergy.nl/contact">Contact</Link></>,
    logo: SunergyLogo,
    ean: true,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    partnerCode: 'SRK'
  },
 
  'sunergy.io-comms.com': {
    title: 'Sunergy',
    favicon: "sunergy/favicon.ico",
    appleTouchIcon: "sunergy/apple-touch-icon.png",
    favicon32: "sunergy/favicon-32x32.png",
    favicon16: "sunergy/favicon-16x16.png",
    idp: 'Sunergy',
    loginClass: 'loginForm',
    footer: <>Sunergy - <Link target="_blank" href="https://sunergy.nl/contact">Contact</Link></>,
    logo: SunergyLogo,
    ean: true,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    partnerCode: 'SRK'
    
  },
  'myrensol.be': {
    title: 'Rensol',
    favicon: "rensol/favicon.ico",
    appleTouchIcon: "rensol/apple-touch-icon.png",
    favicon32: "rensol/favicon-32x32.png",
    favicon16: "rensol/favicon-16x16.png",
    idp: 'Rensol',
    loginClass: 'loginForm',
    footer: <>Rensol - <Link target="_blank" href="https://www.rensol.be/contact/">Contact</Link></>,
    logo: RensolLogo,
    ean: true,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    partnerCode: 'RNSL'
    
  },
 
  'myrensol.io-comms.com': {
    title: 'Rensol',
    favicon: "rensol/favicon.ico",
    appleTouchIcon: "rensol/apple-touch-icon.png",
    favicon32: "rensol/favicon-32x32.png",
    favicon16: "rensol/favicon-16x16.png",
    idp: 'Rensol',
    loginClass: 'loginForm',
    footer: <>Rensol - <Link target="_blank" href="https://www.rensol.be/contact/">Contact</Link></>,
    logo: RensolLogo,
    ean: true,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    partnerCode: 'RNSL'

    
  },
  'energreen.com': {
    title: 'Energreen',
    favicon: "energreen/favicon.ico",
    appleTouchIcon: "energreen/apple-touch-icon.png",
    favicon32: "energreen/favicon-32x32.png",
    favicon16: "energreen/favicon-16x16.png",
    idp: 'Energreen',
    loginClass: 'loginForm',
    footer: <>Energreen- <Link target="_blank" href="https://www.energreen.com/nl-BE/contact">Contact</Link></>,
    logo: EnergreenLogo,
    ean: true,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    partnerCode: 'RBN'
    
  },
 
  'energreen.io-comms.com': {
    title: 'Energreen',
    favicon: "energreen/favicon.ico",
    appleTouchIcon: "energreen/apple-touch-icon.png",
    favicon32: "energreen/favicon-32x32.png",
    favicon16: "energreen/favicon-16x16.png",
    idp: 'Energreen',
    loginClass: 'loginForm',
    footer: <>Energreen- <Link target="_blank" href="https://www.energreen.com/nl-BE/contact">Contact</Link></>,
    logo: EnergreenLogo,
    ean: true,
    iban: false,
    tou: false,
    settings: false,
    allowAdvancedMode: false,
    warnings: false,
    partnerCode: 'RBN'
    
  },
   
  // 'localhost': {
  //   title: 'Sunergy',
  //   favicon: "sunergy/favicon.ico",
  //   appleTouchIcon: "sunergy/apple-touch-icon.png",
  //   favicon32: "sunergy/favicon-32x32.png",
  //   favicon16: "sunergy/favicon-16x16.png",
  //   idp: 'Sunergy',
  //   loginClass: 'loginForm',
  //   footer: <>Sunergy - <Link target="_blank" href="https://sunergy.nl/contact">Contact</Link></>,
  //   logo: SunergyLogo,
  //   ean: false,
  //   iban: false,
  //   tou: false,
  //   settings: false,
  //   allowAdvancedMode: false,
  //   warnings: false,
  // },
};

function getClientInfo(clientString) {
  if (!clientString) {
    const { client } = getQuery();
    clientString = client ?? window.location.hostname;
    
  }

  for (const [k, v] of Object.entries(clients)) {
    if (clientString.includes(k)) {
       return v;
    }
  }
}

export default getClientInfo;
