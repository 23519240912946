import { Typography } from "@mui/material";
import StepComponent from "../components/step.component";
const pattern = "^[a-zA-Z0-9_.,@/:#=\\[\\]\\s-]*$";

export default {
  title: "User email",
  description:
    "User email address used to login to the MyFlexiO App.",
  helperText: {
    header: (
      <Typography variant="h6">
        {" "}
        User email address used to login to the MyFlexiO App.
      </Typography>
    ),
  },
  component: StepComponent,
  schema: {
    required: ["email"],
    properties: {
      email: {
        $id: "basic_email",
        type: "string",
        format: "email",
        title: "Email address",
        description:
          "Enter user's email address. This address must be the email address which the user wants to use for logging in on the MyFlexiO App",
      },
    },
  },
  uiSchema: {
    email: {
      "ui:options": {
        inputType: "email",
      },
      "ui:widget": "email",
    },
  }
};
