import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import DeviceConfigWizardComponent from "./components/device-config-wizard.component";
import { Button, DialogActions, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function DeviceConfigWizardDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const shouldShowOfflineMessage = !props.connected && !props.allowOfflineConfig;

  return (
    <Dialog fullScreen={fullScreen} fullWidth maxWidth="lg" open={props.isOpen}>
      <DialogTitle>FlexiO Configuration</DialogTitle>
      <DialogContent sx={{ padding: fullScreen ? "0" : undefined }}>
        <Box>
          {shouldShowOfflineMessage ? (
            <Typography
              minHeight={150}
              alignContent="center"
              textAlign="center"
              variant="h5"
            >
              ℹ️ Device is not connected!
            </Typography>
          ) : (
          <DeviceConfigWizardComponent {...props}/>
          )}
        </Box>
      </DialogContent>
      {shouldShowOfflineMessage && (
        <DialogActions>
          <Button onClick={props.onClose}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
