import { Typography } from "@mui/material";
import StepComponent from "../components/step.component";
import { get } from "lodash";

export default {
  title: "Energy Tariff",
  description:
    "Choosing the appropriate energy tariff will allow FlexiO to optimize savings and earnings.",
  helperText: {
    header: (
      <>
        <Typography variant="h6">
          Select the correct Tariff structure.
        </Typography>
        <Typography variant="body" fontWeight={500}>
          ℹ️ Necessary for running FlexiO fluently and getting the most out of
          possible savings and earnings.
        </Typography>
      </>
    ),
  },
  component: StepComponent,
  schemaFunction: ({ schemas }) => {
    return {
      ...schemas,
      properties: {
        tariffStructure: schemas.properties.tariffStructure,
      },
      required: ["tariffStructure"],
    };
  },
  diffFunction: (diffObj) => {
    return {
      tariffStructure: get(diffObj, "settings.tariffStructure", false),
    }
  },
  uiSchema: {
    "ui:options": {
      title: false,
      description: false,
    },
    tariffType: {
      "ui:widget": "hidden",
    },
    tariffStructure: {
      "ui:options": {
        title: false,
        description: false,
      },
      tariffType: {
        "ui:widget": "hidden",
      },
    },
  },
};
