import { Templates } from '@rjsf/mui';
import { get } from 'lodash';

const FIELD_TYPES = {
    FIELD_NUMBER: 'field-number',
    FIELD_STRING: 'field-string',
    FIELD_BOOLEAN: 'field-boolean',
    FIELD_OBJECT: 'field-object',
    FIELD_ARRAY: 'field-array',
}

/**
 * Creates a custom field template component that highlights differences between desired and reported values
 * 
 * @param {Object} diffObj - Object containing differences between desired and reported states
 * @returns {Function} A component that renders form fields with visual indicators for differences
 * 
 * The component will:
 * - Check if the field is a primitive type (string, number, boolean)
 * - Look up if there are differences for this field path in the diffObj
 * - Add a 'diff-class' to highlight fields with differences
 * - Render using the base Templates.FieldTemplate
 */
export const CustomFieldTemplate = ({ classNames, ...props }) => {
    const { diffObj } = props.formContext;
    const primitive = classNames.includes(FIELD_TYPES.FIELD_STRING) ||
        classNames.includes(FIELD_TYPES.FIELD_NUMBER) ||
        classNames.includes(FIELD_TYPES.FIELD_BOOLEAN);
    const diff = get(diffObj, props.id.replace('root_', '').replaceAll('_', '.'));
    const rootDiff = diff?.root;

    if ((diff && primitive) || rootDiff) {
        return <Templates.FieldTemplate classNames={`diff-class ${classNames}`} {...props} />;
    }

    return <Templates.FieldTemplate classNames={classNames} {...props} />;
};