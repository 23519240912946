import React, { useState, useEffect } from 'react';
import './device-io-settings.styles.scss';

import { connect } from 'react-redux';
import useSocket from '@lifepowr/components/src/components/socket/useSocket';
import {Auth} from "Amplify.js";

import {nanoid} from "nanoid";
import { mergeWith } from "lodash";

import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgressLoader from '@lifepowr/components/src/components-shared/circular-progress-loader-component/circular-progress-loader.component';
import DeviceIOSettingsForm from './device-io-settings-form-component/device-io-settings-form.component';
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import customizer from '../../../../utils/customizer';
import { nestedOmit } from '../../../../utils';
import { emsModes, EmsMode } from "@lifepowr/components/src/utils/emsModes";

/**
 * Settings list of the device.
 * @param { shadow, deviceId }
 * shadow object from system.
 * device Id corresponds to thingName
 * @returns
 */
const DeviceIOSettings = ({ shadow, deviceId, connected, adminStore }) => {

    const { publish = () => undefined, subscribe } = useSocket();
    const { reported = {} } = shadow || {};
    const { namedShadows, batControlMode, settings, apMode, identify } = reported;
    const [ schema, setSchema ] = useState({}) ;
    const [ retry, setRetry ] = useState(0);
    const [ userInfo, setUserInfo ] = useState({});
    const [ checkedCheckbox , setCheckedCheckbox ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ errorFetchingSchemas, setErrorFetchingSchemas] = useState(false);
    const thingName = deviceId;
    const { admin: adminRole } = adminStore;
    const prePrefix = `$aws/things/${thingName}/shadow`;

    //EMS drop down options
    const setControlMode = (e) => {
        /* Need to use confirmation window before submission */
        console.log("EVENT", e.target)
        const r = window.confirm("Do you really want to change Control Mode?");
        if(!r) return 0;
        const topic = `fleet/devices/${thingName}/state/set`;
        const pubObj = { batControlMode: {mode: Number(e.target.value)} };
        publish(topic, pubObj, true);
    }

    const apModeChange = async (ev) => {
        const topic = `fleet/devices/${thingName}/state/set`;
        const pubObj = { "": {apMode: !apMode} };
        const result = await publish(topic, pubObj, true);

        console.log("DONE", result);
    }

    const identifyModeChange = async (ev) => {
        const topic = `fleet/devices/${thingName}/state/set`;
        const pubObj = { "": {identify: !identify} };
        const result = await publish(topic, pubObj, true);

        console.log("DONE", result);
    }


    //GET SETTINGS
    useEffect(() => {
        Auth.currentUserInfo()
            .then( res => {
                const {attributes: {"custom:role": role} } = res;
                setUserInfo({role});
            });
        async function getSchemas(thingName){
            setLoading(true);
            setErrorFetchingSchemas(false);
            const prefix = `fleet/devices/${thingName}`;
            const result = await publish(`${prefix}/state/interact`, {task:'schemas', args: {type: 'settings'}}, true);
            result.properties.tariffStructure.oneOf = result.properties.tariffStructure.anyOf;
            delete result.properties.tariffStructure.anyOf;

            setSchema(result);
            setLoading(false);
        }
        getSchemas(thingName).catch((e) => {
            console.log("PROBLEM GETING SCHEMAS", e);
            setErrorFetchingSchemas(true);
            setLoading(false);
        });

        subscribe(`fleet/devices/${thingName}/delta/error`, (data) => {
            console.log("GOT A DELTA ERROR", data);
        });

    }, [connected, thingName, retry]);

    //Function that sends a request state change when a new settings object is required
    const onSubmit = (data) => {
        const mergedSettings = mergeWith(Object.create(settings), data, customizer);
        console.log(data, settings, mergedSettings);
        const topic = `fleet/devices/${thingName}/state/set`;
        const clientToken = nanoid();
        const pubObj = { settings: mergedSettings, clientToken };
        publish(topic, pubObj, true);
        setCheckedCheckbox(false);
    };

    const { role } = userInfo;
    let formProps = {
        schema,
        formData: nestedOmit(settings || {}),
        onSubmit
    };

    const modes = Object.entries(emsModes).map(
        ([modeNo, obj])=>{
            const { settable, settablePartner, label, icon: Icon } = obj;
            const controlMode = (isNaN(batControlMode) ? '' : batControlMode);
            const render = (Boolean(adminRole) && settable) || (!adminRole && settablePartner) || Number(modeNo) === controlMode;

            if ( !render ) return null;

            return (
                <MenuItem value={Number(modeNo)} style={{ pointerEvents: 'auto' }}>
                    <EmsMode mode={Number(modeNo)}  />
                </MenuItem>
            );
        }
    ).filter(m=>m);

    const apControl = <Switch checked={apMode} onChange={apModeChange} />
    const identifyControl = <Switch checked={identify} onChange={identifyModeChange} />
    const sliders = connected ?
        <>
            <FormControlLabel
                control={apControl}
                label="AP Mode"
                className="device-io-settings__ap-mode"
            />
            <FormControlLabel
                control={identifyControl}
                label="Identify"
                className="device-io-settings__ap-mode"
            />
        </> :
        null

    return (
        <div className='device-io-settings' style={{height: '100%'}}>
            {
                loading ?
                <div className='device-io-settings__form-loader'>
                    <CircularProgressLoader/>
                </div>
                :
                <>
                    <div className="device-io-settings__form-wrapper">
                        <FormControl className='device-io-settings__ems-wrapper' variant='outlined'>
                            <InputLabel id="battery-mode-label">Battery EMS Mode</InputLabel>
                            <Select labelId="battery-mode-label" label="Battery EMS Mode" onChange={setControlMode} value={(isNaN(batControlMode) ? '' : batControlMode)} className="device-io-settings__mode-select">
                                {modes}
                            </Select>
                            { sliders }
                        </FormControl>
                        { errorFetchingSchemas ?
                        <div>
                            <span>Device is unavailable <Button onClick={() => setRetry(old => old + 1)}>Retry</Button> </span>
                        </div>
                        :
                        <>
                            <Divider className="device-io-settings__ems-divider"/>

                            { adminRole ? <DeviceIOSettingsForm {...formProps}/> : null}
                        </> }

                    </div>
                </>
            }
        </div>
     );
}

const map = (state) => {
    const adminStore = state?.admin;
    const system = state?.system || {};
    const { connected, shadow } = state.system || {};
    return { shadow, connected, adminStore };
}

export default connect( map, null, null )(DeviceIOSettings);
