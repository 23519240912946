import { Typography } from "@mui/material";
import StepComponent from "../components/step.component";
import { generateConverterSchema } from "../../device-details-component/device-details-utils";
import { BRAND_MODEL_UI_SCHEMA } from "../shared";
import { get } from "lodash";

export default {
  title: "Inverter",
  description: "Setting up and configuring the correct inverter.",
  helperText: {
    header: (
      <>
        <Typography variant="h6">
          Select the correct battery/hybrid inverter.
        </Typography>{" "}
        <Typography variant="body" fontWeight={500}>
          ℹ️ Mind: Only FlexiO compatible inverters can be selected.
        </Typography>
      </>
    )
  },
  component: StepComponent,
  schemaFunction: ({ configuration }) => generateConverterSchema(configuration),
  diffFunction: (diffObj) => {
    return {
      model: get(diffObj, "configuration.converter", false),
    }
  },
  uiSchema: BRAND_MODEL_UI_SCHEMA,
};
