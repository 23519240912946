import { get } from "lodash";
import StepComponent from "../components/step.component";

export default {
  title: "Meter",
  description: "Current limitation of the meter (A).",
  component: StepComponent,
  schema: {
    properties: {
      meterCurrentLimitation: {
        type: "number",
        title: "Meter Current Limitation (A)",
        enum: [25, 32, 40, 63, 80],
      },
    },
  },
  diffFunction: (diffObj) => {
    return {
      meterCurrentLimitation: get(diffObj, "settings.meterCurrentLimitation", false),
    }
  },
  uiSchema: {
    meterCurrentLimitation: {
      "ui:widget": "radio",
      "ui:options": {
        inline: false,
      },
    },
  },
};
