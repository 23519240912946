import Goodwe from './Goodwe';
import Solax from './Solax';
import SunSpec from './SunSpec';
import Huawei from './Huawei';
import SMA from './SMA';
import Solis from './Solis';
import SolarEdge from './SolarEdge';
import Victron from './Victron';
import Fronius from './Fronius';
import MTEC from './M-TEC';
import Sigenergy from './Sigenergy';
import Solinteg from './Solinteg';

export default {
	Fronius,
	Goodwe,
	Huawei,
	'M-TEC': MTEC,
	Solax,
	SMA,
	Solis,
	SolarEdge,
	SunSpec,
	Victron,
	Sigenergy,
	Solinteg,
};